<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 mt-0">
        <v-form>
          <div class="ma-2">컨트롤러 정보 {{controller_ID}}</div>
            
          <v-select dense outlined class="ma-1" style="height: 45px; width: auto;" v-show="false"
            id="company" 
            ref="company" 
            label="경영체✽" 
            v-model="company"
            :items="companyItems"
            :menu-props="{ top: false, offsetY: true }"
            :loading="loadingMac" 
            no-data-text="자료(권한)이 없습니다."
            item-text="company_name"
            return-object
            @change="changeCompany()"
            @focus="comboCompanies()"
            clearable
          />

          <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;" 
            id="farm" 
            ref="farm" 
            label="농장/사업장✽" 
            v-model="farm"
            :items="farmItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="farm_name"
            return-object
            @change="changeFarm()"
            @focus="comboFarms()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
            id="building" 
            ref="building" 
            label="축사/건물/구역✽" 
            v-model="building"
            :items="buildingItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="building_name"
            return-object
            @change="changeBuilding()"
            @focus="comboBuildings()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; "
            id="room" 
            ref="room" 
            label="돈방/위치✽" 
            v-model="room"
            :items="roomItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="room_name"
            return-object
            @change="changeRoom()"
            @focus="comboRooms()"
            clearable
          />
          <v-text-field dense outlined class="ma-1" style="height: 45px; width: auto;"
            id="device_name" 
            ref="device_name" 
            label="컨트롤러 식별명✽" 
            v-model="device_name"
            placeholder="컨트롤러 장치명을 지정해주세요."
            maxlength="20"
            clearable
          />
          
          <v-combobox dense outlined class="ma-1" style="height: 45px; width: auto;"
            id="smart_mac" 
            ref="smart_mac" 
            label="맥ID✽" 
            v-model="smart_mac"
            :items="macItems"
            :disabled="smartDisabled"
            @focus="listSmart_mac()"
            placeholder="연결된 멀티게이트(GW)의 맥ID 선택"
          />
          <v-text-field dense outlined style="height: 45px; margin: 5px"
            type=number
            :rules="[rules.slaveMin, rules.slaveMax]" 
            max="15"
            min="0"
            editable 
            id="slave_id" 
            ref="slave_id" 
            label="슬레이브ID✽" 
            placeholder="컨트롤러 슬레이브ID" 
            v-model="slave_id"
            :disabled="smartDisabled"
            maxlength="2"
          />
          <v-select dense outlined style="height: 45px; margin: 5px"
            id="controller_type" 
            ref="controller_type" 
            label="컨트롤러 종류✽" 
            v-model="controller_type"
            :items="controller_typeItems"
            :menu-props="{ top: false, offsetY: true }"
            placeholder="컨트롤러 종류를 선택하세요" 
            item-text="name"
            return-object
          />

          <v-combobox dense outlined style="height: 45px; margin: 5px"
            id="model" 
            ref="model" 
            label="컨트롤러 모델명"
            :items="modelItems" 
            placeholder="식별할 수 있는 컨트롤러의 모델명(새로 입력 가능)" 
            v-model="model"
            @focus="listModels(true)"
          />

          <v-combobox dense outlined style="height: 45px; margin: 5px" 
            v-if="controller_type.code !== 'CV03'"
            id="fan_model" 
            ref="fan_model" 
            label="장치 모델명✽"
            :items="fan_modelItems" 
            placeholder="제어대상 장치의 모델명(새로 입력 가능)" 
            v-model="fan_model"
            @focus="listFanModels(true)"
          />

          <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1 ml-1" style="width: 180px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1" style="width: 180px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>
          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="normal-btn mr-2" style="height: 30px; width: 20px;  margin-bottom: 15px"
                :loading="loadingSave" 
                id="save" 
                ref="save"
                type="submit" 
                @click.prevent="save()">
                저장
              </v-btn>
              <v-btn rounded class="new-btn mr-2" style="height: 30px; width: 20px;  margin-bottom: 15px" 
                id="clear" 
                ref="clear" 
                @click="newRegister()">
                새로
              </v-btn>
              <v-btn rounded class="dialog-btn" style="height: 30px; width: 20px;  margin-bottom: 15px" 
                id="delete" 
                ref="delete" 
                @click="deleteController()">
                삭제
              </v-btn>
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>

      <v-card class="ma-0 ml-1 mt-0" width="700px">
        <v-data-table dense nowrap height="550px" class="elevation-3  v-data-table" style=" margin-left: 1px margin-right: 0px "
          id="items" 
          ref="items"
          :loading="loadingList" 
          :headers="headers"
          :items="items"
          :items-per-page= "500"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          fixed-header
          hide-default-footer
          @click:row="infoDetail"
          sort-by="room"
          group-by="building"
          show-group-by
          item-key="controller_key"
          single-select
          :item-class="itemRowBackground"
          :search="search2 || search"
        >
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="9">
              <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              {{ items[0].building }}
            </th>
          </template>
        </v-data-table>

        <v-card-title>
          <v-text-field dense style="width:185px" class="shrink mr-3"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="맥 ID로 찾기"
            single-line
            hide-details
            @keyup="searchMacMask(search)"
          ></v-text-field>

          <v-text-field dense style="width:250px" class="shrink"
            clearable
            v-model="search2"
            append-icon="mdi-magnify"
            label="사업장 및 돈방명으로 찾기"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-card-actions class="justify-center">
          <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill normal-btn" style="height: 35px;"
            id="refreshList" 
            ref="refreshList" 
            @click="refreshList()">
            <v-icon left dark>mdi-refresh</v-icon>
            목록고침
          </v-btn>
          <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill new-btn" style="height: 35px;"
            id="setting" 
            ref="setting" 
            @click="$router.push('/device/settingcontrol')">
            설정현황
            <v-icon right dark>mdi-blur-linear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
        


    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";

var save_controller_id = "";

export default {
  name: 'RegisterController',
  data: () => ({
  search:'',
  search2:'',
  rules: {
     slaveMin: value => value > -1 || '0 ~ 15',
     slaveMax: value => value < 16 || '0 ~ 15',
  },
    items: [],
    headers: [
      { text: '농장/사업장', align: 'left', value: 'farm', sortable: false, groupable: false, width: "100px", class: 'green darken-4 white--text',divider: false  },
      { text: '돈방/위치', align: 'left', value: 'room', sortable: false, groupable: false, width: "130px", class: 'green darken-4 white--text',divider: true, fixed: true },
      { text: '맥 ID', align: 'left', value: 'mac', sortable: false, groupable: false, width: "120px", class: 'green darken-4 white--text',divider: false },
      { text: 'S_ID', align: 'left', value: 'slave_id', sortable: false, groupable: false, width: "20px",class: 'green darken-4 white--text',divider: false  },
      { text: '컨트롤러종류', align: 'left', value: 'controller_type', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '컨트롤러', align: 'left', value: 'model', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '팬 모델', align: 'left', value: 'fanmodel', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '장치종류', align: 'left', value: 'device_type', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '설치일', align: 'left', value: 'setdate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      { text: '중지일', align: 'left', value: 'deldate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text' },
      // { text: '비고', align: 'left', value: 'history', sortable: false, groupable: false, width: "300px",class: 'green darken-4 white--text' },
    ],


    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],
    device_name: "",

    macItems : [],

    controller_type: "",
    controller_typeItems : Common.comboBaseCode("CV"),
    model: "",
    modelItems : [],

    fan_model: "",
    fan_modelItems : [],
    smart_mac: "",
    slave_id: "",
    setdate: "",
    deldate: "",
    history: "",
    hint: "",

    loadingMac: false,
    loadingSave: false,
    loadingList: false,

    controller_ID: "",
    smartDisabled: true,
    menuSensorSetdate: false,
    menuSensorDeldate: false,

    msgflag: true,
  }),
  created() {
    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.comboRooms();
    this.listModels(false);
    this.listFanModels(false);
    this.$store.commit("resMessage","");
    this.refreshList();
    sessionStorage.setItem("deviceTab",1)
  },

  methods: {
    newRegister() {   // 새로운 센서 등록 준비 입력항목 clear;
      this.clearControllerData();
      this.smartDisabled = false;
      this.$store.commit("resMessage","새로운 디바이스를 입력할 준비가 되었습니다.");
      this.$refs.company.focus();
    },
    clearControllerData() {
      this.smartDisabled = true;
      this.controller_ID = "";
      this.smart_mac = "";
      this.slave_id = "";
      this.model = "";
      this.fan_model = "";
      this.company = {};
      this.controller_type = {};
      
      this.device_type = {};
      this.device_model = "";

      this.farm = {};
      this.building = {};
      this.room = {};
      this.device_name = "";

      this.setdate = "";
      this.deldate = "";
      this.history = "";
      this.hint = "";
    }, 
    checkData() {
      // if (!this.company.company_cd) {
      //   this.$refs.company.focus();
      //   return "경영체를 선택입력하세요";
      // }
      if (!this.farm.farm_cd) {
        this.$refs.farm.focus();
        return "농장/사업장을 선택입력하세요";
      }
      if (!this.building.building_cd) {
        this.$refs.building.focus();
        return "축사/건물/구역을 선택입력하세요";
      }
      if (!this.room.room_cd) {
        this.$refs.room.focus();
        return "돈방/위치를 선택입력하세요";
      }
      if (!this.device_name.trim()) {
        this.$refs.device_name.focus();
        return "컨트롤러 식별명을 입력하세요";
      }
      if (!this.smart_mac) {
        this.$refs.smart_mac.focus();
        return "연결된 멀티게이트의 맥 주소를 입력하세요";
      }
      let mac = this.smart_mac.replace(/:/gi, '')
      if (mac.length !== 12) {
        this.$refs.smart_mac.focus();
        return "멀티게이트의 맥 주소가 유효하지 않습니다.";
      }
      if (!this.slave_id) {
        this.$refs.slave_id.focus();
        return "컨트롤러의 슬레이브ID를 입력하세요.";
      }
      if (Number(this.slave_id) < 0 || Number(this.slave_id) > 15  ) {
        this.$refs.slave_id.focus();
        return "컨트롤러의 슬레이브ID오류입니다.";
      }
      if (!this.controller_type.code) {
        this.$refs.controller_type.focus();
        return "컨트롤러의 종류를 선택입력하세요.";
      }
      if (!this.model) {
        this.$refs.model.focus();
        return "컨트롤러의 모델 식별명을 입력하세요.";
      }
      if(this.controller_type.code !== 'CV03'){
        if (!this.fan_model) {
          this.$refs.fan_model.focus();
          return "팬의 모델 식별명을 입력하세요.";
        }
      }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일을 선택입력하세요";
      }
      return "";
    },
    save() {
      // console.log("model", this.model);
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      save_controller_id = this.smart_mac.replace(/:/gi, '') + this.slave_id; 
      if (this.controller_ID) {
        // console.log("업데이트");
        Apis.updateController({
          mac: this.smart_mac.replace(/:/gi, ''),
          slave_id:  Number(this.slave_id),
          control_division: this.controller_type.code,
          model: this.model.trim().toUpperCase(),
          fanmodel: this.fan_model.trim().toUpperCase() || "",
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.device_name,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              // this.save_controller_id = this.controller_ID + this.slave_id;
              this.clearControllerData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company.code);
        Apis.insertController({
          mac: this.smart_mac.replace(/:/gi, ''),
          slave_id: Number(this.slave_id),
          control_division: this.controller_type.code,
          model: this.model.trim().toUpperCase(),
          fanmodel: this.fan_model.trim().toUpperCase() || "",
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.device_name,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              this.msgFlag = false;
              this.refreshList();
              // this.save_controller_id = this.smart_mac.replace(/:/gi, '') + this.slave_id;
              this.clearControllerData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    deleteController() {
      this.$store.commit("resMessage","");
      if ( this.controller_ID ) {
        if(confirm(`주의 ! \n ${this.room.room_name}/${this.slave_id} 의 \n 팬 컨트롤러 정보가 완전 삭제됩니다. \n 삭제하시겠습니까?`) == true){
          this.loadingMac = true;
          // console.log("mac", this.smart_mac.replace(/:/gi, ''));
          Apis.deleteController({
            mac: this.smart_mac.replace(/:/gi, ''),
            slave_id: this.slave_id,
            },(res) => {  
              if (res.result) {
                this.loadingMac = false;
                this.msgFlag = false;
                this.clearControllerData();
                this.$store.commit("resMessage",res.message);
                save_controller_id = "";
                this.refreshList();
              } else {
                alert(res.message);
                this.loadingMac = false;
              }
            }).catch( (err) => {  // API 오류 처리
                console.log("API 호출 오류",err)
                this.loadingMac = false;
                // alert(err);
            }
          ); 
        }
      }
    },
    async infoDetail(value,row) {
      row.select(true)
      // console.log("select",value)
      save_controller_id = "";
      this.$store.commit("resMessage","");
      this.loadingMac = true;
      this.clearControllerData();
      await this.listModels(false);
      await this.listFanModels(false);

      Apis.infoController({
        mac: value.mac.replace(/:/gi, ''),
        slave_id: value.slave_id,
        },(res) => {  
          if (res.result) {
            // console.log(res.data);
            this.controller_ID = res.data.mac + res.data.slave_id;
            this.smart_mac = Common.getMacMask(res.data.mac);
            this.slave_id = res.data.slave_id;
            this.device_name = res.data.device_name;
            this.controller_type = { code: res.data.control_division.substr(0,4), name: res.data.control_division.substr(4)};
            this.model = res.data.model;
            this.fan_model = res.data.fanmodel || "";
            this.company = {company_cd: res.data.company_cd, company_name: res.data.company};
            this.farm = {farm_cd: res.data.farm_cd, farm_name: res.data.farm};
            this.farmItems.push({farm_cd: res.data.farm_cd, farm_name: res.data.farm});
            this.building = {building_cd: res.data.building_cd, building_name: res.data.building};
            this.buildingItems.push({building_cd: res.data.building_cd, building_name: res.data.building});
            this.room = {room_cd: res.data.room_cd, room_name: res.data.room};
            this.roomItems.push({room_cd: res.data.room_cd, room_name: res.data.room});

            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.history = res.data.history; 
            this.hint = res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.loadingMac = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            this.loadingMac = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadingMac = false;
            // alert(err);
        }
      ); 
    },
    refreshList() {
      this.clearControllerData();

      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingList = true;
      // console.log("리스트");
      Apis.listControllers({
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.mac = Common.getMacMask(element.mac);
        });
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loadingList = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarms 호출 오류",err);
        this.$router.push("/");
        this.loadingRoom = false;
        this.msgFlag = true;
      }) 
    }, 
    searchMacMask(val) {
      this.search = Common.getMacMask(val);
    },
    listSmart_mac() {
      Apis.listSmart_mac({
        building_cd: this.building && this.building.building_cd || "",
        },(res) => {  
          if (res.result) {
            this.macItems = [];
            res.data.forEach(element => {
              this.macItems.push(Common.getMacMask(element));
            });
          } else {
            this.macItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listSmart_mac API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    async listModels(flag) {  // flag: true => 해당장치 모델    false: 전체 모델
      if (flag && !this.controller_type.code) {
        this.$refs.controller_type.focus();
        alert("컨트롤러 종류를 먼저 선택하세요");
        this.$store.commit("resMessage","컨트롤러 종류를 먼저 선택하세요");
        return;
      }
      await Apis.listControllerModels({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        controller_type: this.controller_type && this.controller_type.code || ""
        },(res) => {  
          if (res.result) {
            this.modelItems = res.data;
          } else {
            this.modelItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listControllerModels API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    async listFanModels(flag) {
      if (flag && !this.controller_type.code) {
        this.$refs.controller_type.focus();
        alert("컨트롤러 종류를 먼저 선택하세요");
        this.$store.commit("resMessage","컨트롤러 종류를 먼저 선택하세요");
        return;
      }
      await Apis.listFanModels({
        controller_type: this.controller_type && this.controller_type.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.fan_modelItems = res.data;
          } else {
            this.fan_modelItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listFanModels API 호출 오류",err)
            alert(err);
        }
      ) 
    },
   comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = res.data;
          } else {
            this.companyItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
    },
    comboFarms() {
      Apis.comboFarms({
        // company_cd: this.company && this.company.company_cd || "",
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.farmItems = res.data;
          } else {
            this.farmItems = [];
            alert(res.message);  
          } 
          }).catch( (err) => {  // API 오류 처리
          console.log("comboFarms API 호출 오류",err)
          alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      // if (this.farm && this.farm.company_cd) {
        // this.company = {company_cd: this.farm.company_cd, company_name: this.farm.company_name};
        this.building = {};
        this.room = {};
      // }
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.buildingItems = res.data;
          } else {
            this.buildingItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {company_cd: this.building.company_cd, company_name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {farm_cd: this.building.farm_cd, farm_name: this.building.farm_name};
      }
        this.room = {};
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        building_cd: this.building && this.building.building_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        name_flag: true, 
        },(res) => {  
          if (res.result) {
            this.roomItems = res.data;
          } else {
            this.roomItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {company_cd: this.room.company_cd, company_name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {farm_cd: this.room.farm_cd, farm_name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd) {
        this.building = {building_cd: this.room.building_cd, building_name: this.room.building_name};
        this.device_name = this.room.building_name + " " + this.room.room_name;
      } else{
        this.device_name = "";
      }
    },
    smartMacMask(val) {
      this.smart_mac = Common.getMacMask(val);
    },
    itemRowBackground(item) {
      // console.log("key",item.controller_key,save_controller_id);
      return item.controller_key == save_controller_id  ? 'selected' : '' ;
    },



  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: #88191a !important;
}
</style>
