<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 mt-0">
        <v-form>
          <div class="ma-2">제어장비 정보 {{deodorizerController_id}}
          <!-- 장비 모니터링으로 연결될 버튼 만들어주기  -->
          <!-- <v-btn class="elevation-5 ml-1 mb-1 rounded-pill set-btn" style="height: 25px; width: 100px" v-if="deodorizer_id"
            @click="gotoSetting(deodorizer_id)">
            <v-icon class="mr-1 small" color="#3a4f3f">mdi-image-filter-vintage </v-icon>
            장비 모니터링
          </v-btn> -->
          </div>

          <div class="info">✽장비등록 시 탱크를 제일 처음 입력해주세요</div>

          <v-select dense outlined class="ma-1 shrink" style="height: 45px; width: 365px;"
            id="plc" 
            ref="plc" 
            label="연동PLC✽" 
            v-model="plc"
            :items="plcItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @focus="comboPLC()"
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; width: 365px;"
            id="control_type" 
            ref="control_type" 
            label="장비 종류✽" 
            v-model="control_type"
            :items="control_typeItems"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            return-object
            @change="changeControltype()"
          />

          <v-text-field dense outlined class="ma-1" style="height: 45px; width: 365px;"
            id="device_name" 
            ref="device_name" 
            label="장비명✽" 
            v-model="device_name"
            maxlength="10"
            clearable
          />

          <v-combobox dense outlined class="ma-1 shrink" style="height: 45px;  width: 365px;"
            id="model" 
            ref="model" 
            label="모델명"
            :items="modelItems" 
            placeholder="모델(입력가능)" 
            v-model="model"
            @focus="listDeodorizerControlDeviceModels()"
          />
          
          <div v-show="control_type.code === 'OD02' ||  control_type.code === 'OD04'">
            <v-select dense outlined class="ma-1 shrink" style="height: 45px; width: 365px;"
              id="tank" 
              ref="tank" 
              label="연결탱크✽" 
              v-model="tank"
              :items="tankItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @focus="comboTank()"
            />
          </div>

          <div v-show="control_type.code === 'OD01'">
            <v-layout>
              <v-text-field dense outlined class="mr-1 ml-1" style="height: 45px; width: 180px;"
              id="outPut" 
              ref="outPut" 
              label="생성량✽" 
              v-model.number="outPut"
              type="number"
              suffix="리터/분"
              clearable
              />
              <v-text-field dense outlined class="mr-1" style="height: 45px; width: 180px;"
              id="volume" 
              ref="volume" 
              label="총 용량✽" 
              v-model.number="volume"
              type="number"
              suffix="리터"
              clearable
              />
            </v-layout>
          </div>

          <div v-show="control_type.code === 'OD02'">
            <v-text-field dense outlined class="ma-1" style="height: 45px; width: 365px;"
            id="outPut" 
            ref="outPut" 
            label="생성량✽" 
            v-model.number="outPut"
            type="number"
            suffix="리터/분"
            clearable
            />
          </div>

          <div v-show="control_type.code === 'OD03'">
            <v-text-field dense outlined class="ma-1" style="height: 45px; width: 365px;"
            id="volume" 
            ref="volume" 
            label="총 용량✽" 
            v-model.number="volume"
            type="number"
            suffix="톤"
            clearable
            />
          </div>


          <div v-show="control_type.code === 'OD04'">
            <v-layout>
              <v-text-field dense outlined class="mr-1 ml-1" style="height: 45px; width: 180px;"
              id="minoutput" 
              ref="minoutput" 
              label="최소용량✽" 
              v-model.number="minoutput"
              type="number"
              suffix="리터/분"
              clearable
              />
              <v-text-field dense outlined class="mr-1" style="height: 45px; width: 180px;"
              id="maxoutput" 
              ref="maxoutput" 
              label="최대용량✽" 
              v-model.number="maxoutput"
              type="number"
              suffix="리터/분"
              clearable
              />
            </v-layout>
          </div>

          <div v-show="control_type.code === 'OD01' || control_type.code === 'OD04'">
            <v-layout>
              <v-text-field dense outlined class="ma-1" style="height: 45px; width: 50px;"
              id="maxruntime" 
              ref="maxruntime" 
              label="최대가동✽" 
              v-model.number="maxruntime"
              type="number"
              suffix="분"
              clearable
              />

              <v-text-field dense outlined class="ma-1" style="height: 45px; width: 50px;"
              id="minruntime" 
              ref="minruntime" 
              label="최소가동✽" 
              v-model.number="minruntime"
              type="number"
              suffix="분"
              clearable
              />

              <v-text-field dense outlined class="ma-1" style="height: 45px; width: 50px;"
              id="minstoptime" 
              ref="minstoptime" 
              label="최소중지✽" 
              v-model.number="minstoptime"
              type="number"
              suffix="분"
              clearable
              />
            </v-layout>
          </div>

          <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1 ml-1" style="width: 180px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1" style="width: 180px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>

          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="memo" 
            ref="memo" 
            v-model="memo"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="normal-btn mr-2" style="height: 30px; width: auto;  margin-bottom: 15px"
                :loading="loadingSave" 
                id="save" 
                ref="save"
                type="submit" 
                @click.prevent="save()">
                {{ savemode }}
              </v-btn>
              <v-btn rounded class="new-btn mr-2" style="height: 30px; width: auto;  margin-bottom: 15px" 
                id="clear" 
                ref="clear" 
                @click="newRegister()">
                새로등록
              </v-btn>
              <!-- <v-btn rounded class="dialog-btn" style="height: 30px; width: auto;  margin-bottom: 15px" v-show="deodorizerController_id" 
                id="delete" 
                ref="delete" 
                @click="deleteDeodorizer()">
                삭제
              </v-btn> -->
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
      
      <v-card class="ma-0 ml-1 mt-0" width="700px">
        <!-- <div v-show = "!detailinfo" class="structure_title" >
          <v-icon class="mr-2 ml-2" color="white">mdi-sitemap</v-icon>
         제어 장비 구조
        </div> -->

        <template>
          <v-col v-if = "!detailinfo">
            <div v-show = "!detailinfo" class="structure_title" >
              <v-icon class="mr-2 ml-2" color="white">mdi-sitemap</v-icon>
              제어 장비 구조
            </div>
          
            <v-treeview
              class="structure_info mt-2" dense rounded
              color="#3a4f3f"
              ref="structure"
              v-show = "!detailinfo"
              :items="tree"
              :loading="loadingList"
              item-key="id"
              return-object
              activatable
              open-all
              @update:active="infoDetail"
            >
            <template v-slot:prepend="{ item }" >
                <span class="structure_plc"> {{ item.plc_name }} </span>

                <div class="structure_device">
                  <img :src=controllers[item.type] v-if=controllers[item.type] class="structure_icon mr-1">
                  <span> {{ item.device_name }} </span>
                </div>
            </template>
            </v-treeview>
          </v-col>
        </template>

        <template>
        <v-data-table dense nowrap height="670px" 
          v-show= "detailinfo" 
          class="elevation-3 v-data-table" style=" margin-left: 1px margin-right: 0px "
          id="items" 
          ref="items"
          :loading="loadingList" 
          :headers="headers"
          :items="items"
          :items-per-page= "500"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          group-by="plc_name"
          show-group-by
          fixed-header
          hide-default-footer
          @click:row="infoDetail"
          item-key="id"
          single-select
          :item-class="itemRowBackground"
        >
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="15">
              <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              PLC : {{ items[0].plc_name }}
            </th>
          </template>

        </v-data-table>
        </template>

        <v-card-actions class="justify-center">
          <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill normal-btn" style="height: 35px;"
            id="refreshList" 
            ref="refreshList"
            @click="detailbtn()">
            <v-icon left dart v-if="detailinfo">mdi-sitemap</v-icon>
            <v-icon left dark v-else>mdi-table</v-icon>
            {{ detailbtnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
// import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";
var save_deodorizerController_id = "";

export default {
  name: 'RegisterDeodorizerController',

  data: () => ({
    // compressor: require("@/assets/images/compressor.svg"),
    // hocl: require("@/assets/images/HOCL.svg"),
    // pump: require("@/assets/images/pump.svg"),
    // tank_icon: require("@/assets/images/tank.svg"),


    controllers: {
      컴프레서: require("@/assets/images/compressor.svg") ,
      탱크: require("@/assets/images/tank.svg"),
      차염발생기: require("@/assets/images/HOCL.svg"),
      인버터펌프: require("@/assets/images/pump.svg"),
    },

    items: [],
    headers: [
      // { text: '제어PLC', align: 'left', value: 'plc_name', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '장비종류', align: 'left', value: 'type', sortable: false, groupable: false, width: "120px",class: 'green darken-4 white--text',divider: false  },
      { text: '장비명', align: 'left', value: 'device_name', sortable: false, groupable: false, width: "180px",class: 'green darken-4 white--text',divider: true  },
      { text: '생성량 (리터/분)', align: 'center', value: 'outputpermin', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '최소용량 (리터/분)', align: 'center', value: 'minoutput', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '최대용량 (리터/분)', align: 'center', value: 'maxoutput', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '저장공간 (톤)', align: 'center', value: 'volume', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '최대가동 (분)', align: 'center', value: 'maxruntime', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '최소가동 (분)', align: 'center', value: 'minruntime', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      { text: '최소중지 (분)', align: 'center', value: 'minstoptime', sortable: false, groupable: false, width: "70px",class: 'green darken-4 white--text',divider: false  },
      // { text: '설치일', align: 'center', value: 'setdate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      { text: '중지일', align: 'center', value: 'deldate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text' ,divider: false },
      { text: '비고', align: 'center', value: 'memo', sortable: false, groupable: false, width: "200px",class: 'green darken-4 white--text' ,divider: false },
    ],
    tree:[],
    plc: {},
    plcItems: [],

    control_type: "",
    control_typeItems : Common.comboBaseCode("OD"),
    device_name:"",
        
    model:"",
    modelItems:[],

    tank: {},
    tankItems : [],

    outPut : "",
    volume : "",
    minoutput : "",
    maxoutput : "",

    maxruntime : "",
    minruntime : "",
    minstoptime : "",

    setdate: "",
    deldate: "",
    memo: "",
    hint: "",

    // loadingMac: false,
    loadingSave: false,
    loadingList: false,

    deodorizerController_id: "",

    menuSensorSetdate: false,
    menuSensorDeldate: false,

    // setData:{},
    
    msgflag: true,
    detailinfo: false,
    detailbtnText : "상세보기"

  }),
  created() {
    this.comboPLC();
    this.listDeodorizerControlDeviceModels();
    this.$store.commit("resMessage","");
    this.refreshList();
    sessionStorage.setItem("deviceTab",3)
  },
  computed: {
     savemode() {
        return this.deodorizerController_id ? "수정저장" : "신규저장"; 
     },
  },
  updated(){
    if (!this.detailinfo){
    this.$refs.structure.updateAll(true);
    }
  },

  methods: {
    newRegister() {   // 새로운 센서 등록 준비 입력항목 clear;
      this.clearDeviceData();
      this.$store.commit("resMessage","새로운 디바이스를 입력할 준비가 되었습니다.");
      this.$refs.plc.focus();
    },

    clearDeviceData() {
      this.deodorizerController_id = "";
      this.plc = {};
      this.tank = {};
      this.control_type = {};
      this.device_name = "";

      this.model = "";
      this.outPut = 0,
      this.volume = 0,
      this.minoutput = 0,
      this.maxoutput = 0,

      this.maxruntime = 0,
      this.minruntime = 0,
      this.minstoptime = 0,

      this.setdate = "";
      this.deldate = "";
      this.history = "";
      this.memo = "";
      this.hint = "";
    }, 

    checkData() {
      if (!this.plc.mac) {
        this.$refs.plc.focus();
        return "연결 PLC를 선택하세요";
      }
      if (!this.control_type.code) {
        this.$refs.control_type.focus();
        return "장비 종류를 선택하세요";
      }
      if (!this.device_name.trim()) {
        this.$refs.device_name.focus();
        return "장비 식별명을 선택하세요";
      }
      if (!this.device_name.trim()) {
        this.$refs.device_name.focus();
        return "장비 식별명을 선택하세요";
      }
      if (this.control_type.code === 'OD01'){
        if (!this.outPut || this.outPut < 0 ) {
          this.$refs.outPut.focus();
          return "컴프레셔의 생성량을 입력하세요";
        }
        if (!this.volume || this.volume < 0 ) {
          this.$refs.volume.focus();
          return "컴프레셔의 저장용량을 입력하세요";
        }
        if (!this.maxruntime || this.maxruntime < 0 ) {
          this.$refs.maxruntime.focus();
          return "컴프레셔의 최대 가동 가능시간을 입력하세요";
        }
        if (!this.minruntime || this.minruntime < 0 ) {
          this.$refs.maxruntime.focus();
          return "컴프레셔의 최대 가동 가능시간을 입력하세요";
        }
        if (!this.minstoptime || this.minstoptime < 0 ) {
          this.$refs.maxruntime.focus();
          return "컴프레셔의 최대 가동 가능시간을 입력하세요";
        }
      }
      if (this.control_type.code === 'OD02'){
        if (!this.outPut || this.outPut < 0 ) {
          this.$refs.outPut.focus();
          return "탈취액 발생기의 생성량을 입력하세요";
        }
      }
      if (this.control_type.code === 'OD03'){
        if (!this.volume || this.volume < 0 ) {
          this.$refs.volume.focus();
          return "탱크용량의 용량을 입력하세요";
        }
      }
      if (this.control_type.code === 'OD04'){
        if (!this.minoutput || this.minoutput < 0 || this.minoutput > this.maxoutput ) {
          this.$refs.minLimit.focus();
          return "펌프의 최소용량을 입력하세요";
        }
        if (!this.maxoutput || this.maxoutput < 0 ) {
          this.$refs.maxLimit.focus();
          return "펌프의 최대용량을 입력하세요";
        }
        if (!this.maxruntime || this.maxruntime < 0) {
          this.$refs.maxruntime.focus();
          return "펌프의 최대 가동 가능시간을 입력하세요";
        }
        if (!this.minruntime || this.minruntime < 0 ) {
          this.$refs.maxruntime.focus();
          return "펌프의 최대 가동 가능시간을 입력하세요";
        }
        if (!this.minstoptime || this.minstoptime < 0 ) {
          this.$refs.maxruntime.focus();
          return "펌프의 최대 가동 가능시간을 입력하세요";
        }
      }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일을 입력하세요.";
      }
      return "";
    },
    save() {
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      save_deodorizerController_id = this.deodorizerController_id;
      if (this.deodorizerController_id) {
        Apis.updateDeodorizerControlDevice({
          id:  Number(this.deodorizerController_id),
          plc_id : this.plc.id,
          mac: this.plc.mac,
          slave_id:  this.plc.slave_id,
          plc_name : this.plc.name,
          device_name :	this.device_name,
          division : this.control_type.code,
          model : this.model.trim().toUpperCase(),
          outputpermin : ["OD01","OD02"].includes(this.control_type.code) ? this.outPut : 0,
          volume :  ["OD01","OD03"].includes(this.control_type.code) ? this.volume : 0,
          minoutput : ["OD04"].includes(this.control_type.code) ? this.minoutput : 0,
          maxoutput : ["OD04"].includes(this.control_type.code) ? this.maxoutput : 0,
          maxruntime : ["OD01","OD04"].includes(this.control_type.code) ? this.maxruntime : 0,
          minruntime : ["OD01","OD04"].includes(this.control_type.code) ? this.minruntime : 0,
          minstoptime : ["OD01","OD04"].includes(this.control_type.code) ? this.minstoptime : 0,
          setdate :	this.setdate,
          deldate :	this.deldate,
          memo : this.memo.trim(),
          tank: this.tank.id,  
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              this.save_deodorizerController_id = this.deodorizerController_id;
              this.clearDeviceData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("updateDeodorizerControlDevice API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        Apis.insertDeodorizerControlDevice({
          plc_id: this.plc.id,
          mac: this.plc.mac,
          slave_id:  this.plc.slave_id,
          plc_name : this.plc.name,
          device_name :	this.device_name,
          division : this.control_type.code,
          model : this.model.trim().toUpperCase(),
          outputpermin : ["OD01","OD02"].includes(this.control_type.code) ? this.outPut : 0,
          volume :  ["OD01","OD03"].includes(this.control_type.code) ? this.volume : 0,
          minoutput : ["OD04"].includes(this.control_type.code) ? this.minoutput : 0,
          maxoutput : ["OD04"].includes(this.control_type.code) ? this.maxoutput : 0,
          maxruntime : ["OD01","OD04"].includes(this.control_type.code) ? this.maxruntime : 0,
          minruntime : ["OD01","OD04"].includes(this.control_type.code) ? this.minruntime : 0,
          minstoptime : ["OD01","OD04"].includes(this.control_type.code) ? this.minstoptime : 0,
          setdate :	this.setdate,
          deldate :	this.deldate,
          memo : this.memo.trim(),
          tank: this.tank.id,
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              save_deodorizerController_id = this.deodorizerController_id;
              this.clearDeviceData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("insertDeodorizerControlDevice API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    async infoDetail(value,row) {
      // console.log("Value",value,"row",row);
      this.$store.commit("resMessage","");
      save_deodorizerController_id = "";
      this.clearDeviceData();

      // 상세내역이 불요한 아이템 Pass
      let tmpId = 0; 
      if (row) {
        tmpId = value.id; 
        row.select(true)
      } else {
        if (!value.length) return;
        if (!value[0].id) return;
        if (value[0].id > 1000) return;
        tmpId = value[0].id; 
      }
      // ==============================

      await this.listDeodorizerControlDeviceModels();

      Apis.infoDeodorizerControlDevice({
        id: tmpId ,
        },(res) => {  
          if (res.result) {
            this.deodorizerController_id = res.data.id;
            this.plc = res.data.plc;
            this.control_type = res.data.type;
            this.device_name = res.data.device_name;
            this.model = res.data.model;
            this.outPut = res.data.outputpermin;
            this.volume = res.data.volume;
            this.tankItems.push(res.data.tank);
            this.tank = res.data.tank;
            this.runLimit = res.data.runlimit;
            this.minoutput = res.data.minoutput;
            this.maxoutput = res.data.maxoutput;
            this.maxruntime = res.data.maxruntime;
            this.minruntime = res.data.minruntime;
            this.minstoptime = res.data.minstoptime;
            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.memo = res.data.memo; 
            this.hint = res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            // this.loadingMac = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("infoDeodorizerControlDevice API 호출 오류",err)
            // this.loadingMac = false;
            // alert(err);
        }
      ); 
    },
    refreshList() {
      this.clearDeviceData();

      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingList = true;
      this.items = [];

      if (this.detailinfo) {
        Apis.listDeodorizerControlDevices({
        } ,(res) => {  // 정상처리
          res.data.forEach((data) => {    // 종류에 따른 불필요한 항목 0 -> '-' 로 치환 
            let tmp = {};
            for(let [key, value] of Object.entries(data)) {
              tmp[key] = (value !== 0) ? value : "-";
            }
          this.items.push(tmp);
        })
          
          if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.msgFlag = true;
          this.loadingList = false;
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("listDeodorizerControlDevices 호출 오류",err);
          sessionStorage.setItem("deviceTab",0)
          this.$router.push("/");
          this.msgFlag = true;
        }) 

      } else {
        Apis.treeDeodorizerControlDevices ({
        } ,(res) => {  // 정상처리
          this.tree = res.data;

        if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.msgFlag = true;
          this.loadingList = false;
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("listDeodorizerControlDevices 호출 오류",err);
          sessionStorage.setItem("deviceTab",0)
          this.$router.push("/");
          this.msgFlag = true;
        }) 
      }
    },

    async listDeodorizerControlDeviceModels() {
      await Apis.listDeodorizerControlDeviceModels({
        // stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.modelItems = res.data;
            // console.log(res.data);
          } else {
            this.modelItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listDeodorizerControlDeviceModels API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboPLC() {
      Apis.comboPLC({
        farm_cd: this.farm && this.farm.farm_cd || "",
        },(res) => {  
          if (res.result) {
            this.plcItems = res.data;
          } else {
            this.plcItems = [];
            // alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboPLC API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboTank(){

      Apis.comboDeodorizerControlDevices({
        mac: this.plc.mac,
        slave_id: this.plc.slave_id,
        division: "OD03",
        },(res) => {  
          if (res.result) {
            this.tankItems = res.data;
            // console.log(res.data);

            if (this.tankItems.length == 0) {
              this.$store.commit("resMessage","탱크를 먼저 등록하세요");
              this.newRegister();
              alert("탱크를 먼저 등록하세요");
              return;
            }

          } else {
            this.tankItems = [];
            // alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboDeodorizerControlDevices API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    changeControltype(){
      if (this.plc && this.control_type ) {
        this.device_name = this.plc.name + " " +this.control_type.name;
      }
    },

    itemRowBackground(item) {
      // console.log("key",item.id);
      return item.id == save_deodorizerController_id ? 'selected' : '' ;
    },

    detailbtn(){
      this.detailinfo = !this.detailinfo;
      this.detailbtnText = this.detailinfo ? "장비구조보기" : "상세보기"
      this.refreshList();
    },

    // gotoSetting(id) {
    //   this.$router.push({
    //     name: "DeodorizerSet",
    //     params: {
    //       id: id
    //     }
    //   });
    // }


  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
::v-deep .v-text-field__suffix {
  font-size: 13px;
  letter-spacing: -1px;
}
.structure_title{
  font-size:15px;
  letter-spacing: -0.5px;
  font-weight:bold;
  color:white;
  background-color: #3a4f3f;
  width:150px;
  padding:5px;
  border-radius: 10px;
}
.structure_info{
  font-size:15px;
  letter-spacing: -0.5px;
  color:#3a4f3f;
}
.structure_plc{
  color:#3a4f3f;
  font-weight:bold;
  font-size:16px;
}
.structure_device{
  display:flex;
}
.structure_icon{
  width:25px;
  height:25px;
}
::v-deep .v-treeview-node__content:nth-child(odd), .structure_icon{
  color:#6B7B6E;
  font-weight: bold;
}
.info{
  font-size:12px;
  background-color: white !important;
  text-align: end;
  color:grey;
}
.set-btn{
  background-color: #e8e7e7 !important;
  color:#3a4f3f;
  font-size:11px;
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.refer_btn{
  height:45px; 
  width:190px; 
  background-color: #3a4f3f; 
  color:white; 
  border-color: transparent;
}

.data_table{
  text-indent: -999px;

}
.data_table:after{
  text-indent:0px;
  float: left;
  content: '-';
}

.green{
  color: #6B7B6E;
}
.blue{
  color:#304E5C;
}

</style>
