<template>
  <v-container>
      <v-tabs color="#3a4f3f" centered 
        v-model="tabs"
        >
        <v-tab style="width: 175px">
          <img :src="gateway" class="img"/>
          <span class="tab_text_green ml-2">멀티 게이트 </span>
        </v-tab>
        <v-tab style="width: 175px">
          <img :src="controller" class="img"/>
          <span class="tab_text_green ml-2">컨트롤러 </span>
        </v-tab>
        <v-tab style="width: 175px">
          <img :src="sensornode" class="img"/>
          <span class="tab_text_green ml-2"> 센서 노드 </span>
        </v-tab>
        <v-tab style="width: 175px">
          <img :src="control_device" class="img"/>
          <span class="tab_text_green ml-2"> 탈취 장치</span>
        </v-tab>
        <v-tab style="width: 175px">
          <img :src="fog_line" class="img"/>
          <span class="tab_text_green ml-2"> 탈취 라인</span>
        </v-tab>
  
        <v-tab-item>
            <v-container>
              <RegisterSmartbox />
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
              <RegisterController />
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
              <RegisterSensorNode />
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
              <RegisterDeodorizerController />
            </v-container>
        </v-tab-item>
        <v-tab-item>
            <v-container>
              <RegisterDeodorizer />
            </v-container>
        </v-tab-item>
      </v-tabs>

 </v-container>
</template>

<script>
import Apis from '@/api/apis'
import RegisterController from "./child/RegisterController.vue";
import RegisterSensorNode from "./child/RegisterSensorNode.vue";
import RegisterSmartbox from "./child/RegisterSmartbox.vue";
import RegisterDeodorizer from  "./child/RegisterDeodorizer.vue";
import RegisterDeodorizerController from  "./child/RegisterDeodorizerController.vue";

export default {
  name: 'RegisterDevice',
  components: {
    RegisterSensorNode,
    RegisterController,
    RegisterSmartbox,
    RegisterDeodorizer,
    RegisterDeodorizerController,
  },
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    // console.log("TAB",sessionStorage.getItem("deviceTab"))
  },
  data () {
    return {
      controller: require("@/assets/images/controller.svg"),
      control_device: require("@/assets/images/control_device.svg"),

      gateway: require("@/assets/images/gateway.svg"),
      sensornode: require("@/assets/images/sensornode.svg"),
      sensor_node: require("@/assets/images/sensor_node.svg"),

      fog_line: require("@/assets/images/fog_line.svg"),

      tabs: Number(sessionStorage.getItem("deviceTab")),
    }
  },
}
</script>
<style lang="scss" scoped >
.tab_text_green{
  margin-bottom: 3px;
  color:#3a4f3f;
  font-size:14pt !important;
  letter-spacing: -1px;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}
.tab_text_red{
  color:#88191a;
  font-size:14pt !important;
  font-weight: bold !important;
  // background-color: #3a4f3f !important;
}

.img{
  width:25px;
  height:25px;
  // background-color: #3a4f3f;
}
</style>
