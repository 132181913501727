<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 pa-1 mt-0" width="350px">
        <v-form id=sensor >
          <div class="ma-2">센서노드 정보 {{ sensor_ID }} </div>
            
          <v-layout style="height: 50px; width: 380px;">
            <v-text-field dense outlined class="shrink mr-1" style="height: 45px; width: 200px; margin: 5px;"
              id="sensor_mac" 
              ref="sensor_mac" 
              label="센서노드 맥✽" 
              placeholder="mac주소를 입력해주세요."
              v-model="sensor_mac"
              @keyup="sensorMacMask(sensor_mac)"
              maxlength="20"
              clearable
            />
            <!-- 센서정보 팝업 -->
            <v-dialog
              v-model="dialog"
              max-width="750"
              max-height="100"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="action_btn normal_btn_color ma-2" style="height: 35px !important; border-radius:20px !important;" 
                  v-bind="attrs"
                  :v-on="sensor_mac && sensor_mac.length == 12 ||sensor_mac && sensor_mac.length > 13  ? 'on' : 'off' "
                  :loading="loadingCheckData"
                  id="checkData" 
                  ref="checkData" 
                  @click=" sensor_mac && sensor_mac.length == 12 || sensor_mac && sensor_mac.length > 13 ? checkData() :this.$store.commit('resMessage','맥 아이디를 확인하세요');"
                >
                  수신확인
                </v-btn>
              </template>

              <v-card class="dialog_content">
                <v-card-text>

                  <v-row class="text-center">
                    <v-col class="mb-5 mt-5">
                      <h2>{{ updated_at }}</h2>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                      <VueSvgGauge
                        v-if="chartData.temp1"
                        class="mini-gauge"
                        :start-angle="0"
                        :end-angle="360"
                        :value="chartData.temp1"
                        :min="-20"
                        :max="50"
                        :separator-step="0"
                        :scale-interval="10"
                        :inner-radius="80"
                      >
                        <div class="inner-text">
                          <div class="sensor_title">
                            <v-icon color="#3a4f3f">mdi-thermometer</v-icon>
                            온도
                          </div>
                          <span class="sensor_value">{{chartData.temp1}}</span>
                        </div>
                      </VueSvgGauge>

                      <VueSvgGauge
                        v-if="chartData.hum1"
                        class="mini-gauge"
                        :start-angle="0"
                        :end-angle="360"
                        :min="0"
                        :max="100"
                        :value="chartData.hum1"
                        :separator-step="0"
                        :scale-interval="10"
                        :inner-radius="80"
                      >
                        <div class="inner-text">
                          <div class="sensor_title">
                            <v-icon color="#3a4f3f">mdi-water</v-icon>
                            습도
                          </div>
                          <span class="sensor_value">{{chartData.hum1}}</span>
                        </div>
                      </VueSvgGauge>

                      <VueSvgGauge
                        v-if="chartData.co21"
                        class="mini-gauge"
                        :min="0"
                        :max="500"
                        :start-angle="0"
                        :end-angle="360"
                        :value="chartData.h2s1"
                        :separator-step="0"
                        :scale-interval="10"
                        :inner-radius="80"
                      >
                        <div class="inner-text">
                          <div class="sensor_title">
                            <v-icon color="#3a4f3f">mdi-weather-cloudy</v-icon>
                            이산화탄소
                          </div>
                          <span class="sensor_value">{{chartData.co21}}</span>
                        </div>
                      </VueSvgGauge>

                      <VueSvgGauge
                        v-if="chartData.nh31"
                        class="mini-gauge"
                        :start-angle="0"
                        :end-angle="360"
                        :min="0"
                        :max="100"
                        :value="chartData.nh31"
                        :separator-step="0"
                        :scale-interval="10"
                        :inner-radius="80"
                      >
                        <div class="inner-text">
                          <div class="sensor_title">
                            <v-icon color="#3a4f3f">mdi-waves</v-icon>
                            암모니아
                          </div>
                          <span class="sensor_value">{{chartData.nh31}}</span>
                        </div>
                      </VueSvgGauge>

                      <VueSvgGauge
                        v-if="chartData.h2s1 > -1"
                        class="mini-gauge"
                        :start-angle="0"
                        :end-angle="360"
                        :value="chartData.h2s1"
                        :separator-step="0"
                        :scale-interval="10"
                        :inner-radius="80"
                      >
                        <div class="inner-text">
                          <div class="sensor_title">
                            <v-icon color="#3a4f3f">mdi-weather-fog</v-icon>
                            황화수소
                          </div>
                          <span class="sensor_value">{{chartData.h2s1}}</span>
                        </div>
                      </VueSvgGauge>

                  </v-row>
                </v-card-text>

                <v-card-actions align-content-center>
                  <v-spacer></v-spacer>
                  <v-btn rounded class="new-btn" style="height: 30px; width: 20px;  margin-bottom: 15px" 
                    id="confirm" 
                    ref="confirm" 
                    @click="dialog = false">
                    확인
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- 센서정보 팝업 끝 -->
          </v-layout>
            <v-select dense outlined class="ma-1 mt-8" style="height: 45px; width: auto;" v-show=false
              id="company" 
              ref="company" 
              label="경영체✽" 
              v-model="company"
              :items="companyItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다.."
              item-text="name"
              return-object
              @change="changeCompany()"
              @focus="comboCompanies()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;" 
              id="farm" 
              ref="farm" 
              label="농장/사업장✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeFarm()"
              @focus="comboFarms()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
              id="building" 
              ref="building" 
              label="축사/건물/구역✽" 
              v-model="building"
              :items="buildingItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeBuilding()"
              @focus="comboBuildings()"
              clearable
            />
            <v-select dense outlined  class="ma-1" style="height: 45px; width: auto;"
              id="room" 
              ref="room" 
              label="돈방/위치✽" 
              v-model="room"
              :items="roomItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeRoom()"
              @focus="comboRooms()"
              clearable
            />
            <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1 ml-1" style="width: 180px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-1" style="width: 180px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="측정중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>
          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="historySensor" 
            ref="historySensor" 
            v-model="historySensor"
            value=""
            persistent-hint :hint="hintSensor"
          ></v-textarea>
      
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="action_btn normal_btn_color mr-2" style="margin-bottom: 15px"
                :loading="loadingSave" 
                id="save" 
                ref="save" 
                @click="saveSensor()">
                저장
              </v-btn>
              <v-btn rounded class="action_btn new_btn_color mr-2" style="margin-bottom: 15px" 
                id="clear" 
                ref="clear" 
                @click="newSensorRegister()">
                새로등록
              </v-btn>
              <v-btn rounded class="action_btn cancel_btn_color mr-2" style="margin-bottom: 15px" 
                v-show="sensor_ID"
                id="delete" 
                ref="delete" 
                @click="deleteSensor()">
                센서제거
              </v-btn>
              <v-btn rounded class="action_btn close_btn_color" style="margin-bottom: 15px" 
                id="close" 
                ref="close" 
                @click="$router.go(-1)">
                닫기
              </v-btn>
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card class="ma-0 ml-1 mt-0" width="700px">
        <v-layout wrap>
          <v-radio-group dense outlined row style="height: 30px; margin-left: 10px; margin-top: 2px"
                id="selOption" 
                ref="selOption" 
                v-model="selOption"
              >
                <v-radio
                  label="가동" value="live" color="#3a4f4f" @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="중지" value="del" color="#88191A"  @click="refreshList()"
                ></v-radio>
                <v-radio
                  label="전체" value="all" color="#004297"  @click="refreshList()"
                ></v-radio>
              </v-radio-group>
              <v-spacer></v-spacer>
          </v-layout>
        <v-data-table dense nowrap height="600px" class="elevation-3  v-data-table" style="margin-left: 1px; margin-right: 0px; "
          id="items" 
          ref="items"
          :loading="loadingList" 
          :headers="headers"
          :items="items"
          :items-per-page= "500"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          fixed-header
          hide-default-footer
          @click:row="infoDetail"
          sort-by="room"
          group-by="farm"
          show-group-by
          item-key="id"
          single-select
          :item-class="itemRowBackground"
          :search="search || search2"
        >
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="7">
              <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              {{ items[0].farm }}
            </th>
          </template>
        </v-data-table>
        <v-card-title>
          <v-text-field dense style="width:185px" class="shrink mr-3"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="맥 ID로 찾기"
            single-line
            hide-details
            @keyup="searchMacMask(search)"
          ></v-text-field>

          <v-text-field dense style="width:250px" class="shrink"
            clearable
            v-model="search2"
            append-icon="mdi-magnify"
            label="경영체 및 건물명으로 찾기"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

      <v-card-actions class="justify-center">
        <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill refresh_btn normal_btn_color" style="height: 45px !important;"
          id="refreshList" 
          ref="refreshList" 
          @click="refreshList()">
          <v-icon left dark>mdi-refresh</v-icon>
          목록고침
        </v-btn>
      </v-card-actions>

      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";
import URLS from "@/api/urls.js";
import { VueSvgGauge } from 'vue-svg-gauge'
// import { GChart } from "vue-google-charts";

// const gaugeWidth = 150;
// const gaugeHeight = 150;

var save_sensor_id = "";

export default {
  name: 'RegisterSensorNode',
  components: {
    VueSvgGauge
    // GChart,
  },

  data: () => ({
    temp: require("@/assets/value/temp.svg"),
    hum: require("@/assets/value/humidity.svg"),
    nh3: require("@/assets/value/nh3.svg"),
    h2s: require("@/assets/value/h2s.svg"),
    co2: require("@/assets/value/co2.svg"),

    chartData: {
      temp1:'',
      hum1:'',
      co21:'',
      nh31:'',
      h2s1:'',

      temp: [
        ["Lavel", "Value"],
        ["온도", 0],
      ],
      hum: [
        ["Lavel", "Value"],
        ["습도", 0],
      ],
      co2: [
        ["Lavel", "Value"],
        ["CO₂", 0],
      ],
      nh3: [
        ["Lavel", "Value"],
        ["NH₃", 0],
      ],
      h2s: [
        ["Lavel", "Value"],
        ["h2s", 0],
      ],
    },

    search: "",
    search2:'',

    items: [],
    headers: [
      { text: '농장/사업장', align: 'left', value: 'farm', sortable: false, groupable: true, width: "100px", class: 'green darken-4 white--text', divider: false, fixed: true },
      { text: '건물', align: 'left', value: 'building', sortable: false, groupable: false, width: "150px", class: 'green darken-4 white--text', divider: false, fixed: true },
      { text: '돈방/위치', align: 'left', value: 'room', sortable: false, groupable: false, width: "140px", class: 'green darken-4 white--text',divider: true, fixed: true  },
      { text: '센서 맥 ID', align: 'left', value: 'sensor_mac', sortable: false, groupable: false, filterable: true, width: "100px", class: 'green darken-4 white--text',divider: false },
      // { text: '설치일', align: 'left', value: 'setdate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '중지일', align: 'left', value: 'deldate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text' },
      { text: '비고', align: 'left', value: 'history', sortable: false, groupable: false, width: "300px",class: 'green darken-4 white--text' },
    ],

    dialog: false,
    updated_at: "-",

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],

    sensor_ID: "",
    sensor_mac: "",
    // smart_mac: "",
    // sensor_id: "",
    setdate: "",
    deldate: "",
    historySensor: "",

    msgFlag: true,
    selOption: "live",
    loadingSensorMac: false,
    menuSensorSetdate: false,
    menuSensorDeldate: false,
    loadingSave: false,
    loadingList: false,
    loadingCheckData: false,
    receiveOK: false, 

  }),
  created() {
    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.comboRooms();
    this.$store.commit("resMessage","");
    this.refreshList();
    sessionStorage.setItem("deviceTab",2)
  },

  methods: {
    chartClear() {
      this.updated_at ="수신 없음";
      this.chartData.temp = [["Lavel", "Value"], ["온도", 0]];
      this.chartData.hum = [["Lavel", "Value"], ["습도", 0]];
      this.chartData.co2 = [["Lavel", "Value"], ["CO₂", 0]];
      this.chartData.nh3 = [["Lavel", "Value"], ["NH₃", 0]];
      this.chartData.h2s = [["Lavel", "Value"], ["h2s", 0]];

    },
    checkData() {
      this.receiveOK = false; 
      this.chartClear();
      let mac = this.sensor_mac.replace(/:/gi, '');
      this.dialog= false;

      if ( mac.length !== 10 && mac.length < 12) {
        this.$store.commit('resMessage','맥 아이디를 확인하세요');
        this.dialog= false;
        return;
      }  
      // console.log("sensor_mac",this.sensor.code);
      this.loadingCheckData= true; 
      this.$store.commit("resMessage","");
      this.$axios
        .get(URLS.redis.lastValue + '/' + mac, null)
        .then((res) => {
          if (res.data) {
            // console.log("Response Data : " + JSON.stringify(res.data));
            // console.log(this.sensor_mac.length)
            const LabelValue = ["Lavel", "Value"];

            this.chartData.temp1 = res.data.value.temp;
            this.chartData.hum1 = res.data.value.hum;
            this.chartData.co21 = res.data.value.co2;
            this.chartData.nh31 = res.data.value.nh3;
            this.chartData.h2s1 = res.data.value.h2s;

            this.chartData.temp = [LabelValue, ["온도", res.data.value.temp]];
            this.chartData.hum = [LabelValue, ["습도", res.data.value.hum]];
            this.chartData.co2 = [LabelValue, ["CO₂", res.data.value.co2]];
            this.chartData.nh3 = [LabelValue, ["NH₃", res.data.value.nh3]];
            this.chartData.h2s = [LabelValue, ["H₂S", res.data.value.h2s]];

            this.updated_at = res.data.local_dt;
            this.$store.commit("resMessage","정상 처리되었습니다.");
            this.loadingCheckData= false; 
            this.dialog= true;
            this.receiveOK = true; 
          } else {
            this.$store.commit("resMessage","센서정보가 없습니다.");
            this.loadingCheckData= false; 
            this.dialog= false; 
            // alert(this.sensor.name + 'Sensor 정보 없음')
          }
        });

    },
    refreshList() {
      this.clearSensorData();
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingList = true;
      // console.log("리스트");
      Apis.listSensors({
        selOption: this.selOption,
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          element.sensor_mac = Common.getMacMask(element.sensor_mac);
        });
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loadingList = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listSensors 호출 오류",err);
        this.$router.push("/");
        this.loadingRoom = false;
        this.msgFlag = true;
      }) 
    }, 
    infoDetail(value,row) {
      row.select(true)
      // console.log("select",value)
      save_sensor_id = "";
      this.$store.commit("resMessage","");
      this.loadingMac = true;
      this.clearSensorData();
      Apis.infoSensorByID({
        id: value.id, 
        },(res) => {  
          if (res.result) {
            // console.log(res.data);
            this.sensor_ID = res.data.id;
            this.sensor_mac = Common.getMacMask(res.data.sensor_mac);
            this.company = {code: res.data.company_cd, name: value.company};
            this.farm = {code: res.data.farm_cd, name: value.farm};
            this.building = {code: res.data.building_cd, name: value.building};
            this.room = {code: res.data.room_cd, name: value.room + " " + value.farm };
            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.historySensor = res.data.history; 
            this.$store.commit("resMessage",res.message);
            this.hintSensor = res.data.regdate + "에 등록, " + res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            this.receiveOK = false; 
            this.loadingSensor = false;
          } else {
            this.loadingSensor = false;
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("infoSensor API 호출 오류",err)
            this.loadingSensor = false;
            // alert(err);
        }
      ); 
    },
    saveSensor() { 
      // console.log("saveSensor");
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkSensorData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        return;
      }
      this.loadingSave = true;
      let mac = this.sensor_mac.replace(/:/gi, '');

      if (this.sensor_ID) {
        // console.log("업데이트");
        Apis.updateSensor({
          "id" : this.sensor_ID,
          "sensor_mac": mac,
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room.code,
          "zone_no" :	1,
          "device_name" :	this.room.name.replace(this.farm.name,"").trim(),
          "setdate" :	this.setdate,
          "deldate" :	this.deldate || "",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              save_sensor_id = this.sensor_ID;
              this.clearSensorData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              console.log("updateSensor API 호출 오류",res.message)
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("updateSensor API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        if (!this.receiveOK) {
          if (!confirm("데이터 수신이 확인 되지 않았습니다. \n 그래도 우선 등록 하시겠습니까?"))  return;
        } 
        Apis.insertSensor({
          "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
          "smart_mac": "",
          "sensor_id": 0,
          "company_cd": this.company.code,
          "farm_cd" : this.farm.code,
          "building_cd" :	this.building.code,
          "room_cd" :	this.room.code,
          "zone_no" :	1,
          "device_name" :	this.room.name.replace(this.farm.name,"").trim(),
          "setdate" :	this.setdate,
          "deldate" :	"",
          "history" : this.historySensor.trim(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              save_sensor_id = "";
              this.clearSensorData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              this.$store.commit("resMessage",res.message);
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("insertSensor API 호출 오류",err)
              alert(err);
            }
        )
      }
    },
    deleteSensor() { 
      if (!confirm("경고!!!\n 해당 센서가 완전히 제거됩니다 !!\n 제거후 복구는 불가능 합니다.\n\n 완전히 제거 하시겠습니까?")) return;
      let reason = prompt("제거 사유를 입력하세요","");
      if (!reason) return;

      this.$store.commit("resMessage","");
      this.loadingSave = true;

      Apis.deleteSensor({
        "sensor_mac": this.sensor_mac.replace(/:/gi, ''),
        "history" : reason.trim(),
        },(res) => {  
          if (res.result) {
            this.$store.commit("resMessage",res.message);
            this.loadingSave = false;
            this.msgFlag = false;
            save_sensor_id = "";
            this.clearSensorData();
            this.refreshList();
          } else {
            this.loadingSave = false;
            this.$store.commit("resMessage",res.message);
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("deleteSensor API 호출 오류",err)
            alert(err);
        })
    },
    newSensorRegister() {   // 새로운 센서 등록 준비 입력항목 clear;
      this.clearSensorData();
      this.sensorDisabled = false;
      this.$refs.sensor_mac.focus();
      this.$store.commit("resMessage","새로 입력 준비가 되었습니다, 정보를 센서 맥아이디 부터 입력해주세요.");
    },
    clearSensorData() {
      this.sensor_ID = "";
      this.sensor_mac = "";
      this.company = {};
      this.farm = {};
      this.building = {};
      this.room = {};
      this.setdate = "";
      this.deldate = "";
      this.historySensor = "";
      this.receiveOK = false; 
      this.hintSensor = "";

    }, 
    checkSensorData() {
      if (!this.sensor_mac) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 아이디를 입력하세요";
      }
      let mac = this.sensor_mac.replace(/:/gi, '')
      if (mac.length == 10) {
        if (isNaN(mac)) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 아이디가 유효하지 않습니다.";
        }
      } else if (mac.length > 14 ||  mac.length < 12) {
        this.$refs.sensor_mac.focus();
        return "센서 맥 아이디가 유효하지 않습니다.";
      }
      if (!this.farm.code) {
        this.$refs.farm.focus();
        return "농장/사업장을 선택입력하세요";
      }
      if (!this.building.code) {
        this.$refs.building.focus();
        return "축사/건물/구역을 선택입력하세요";
      }
      if (!this.room.code) {
        this.$refs.room.focus();
        return "돈방/위치를 선택입력하세요";
      }
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일(가동시작일)을 선택입력하세요";
      }
      return "";
    },
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
    },
    comboFarms() {
      Apis.comboFarms({
        // company_cd: this.company && this.company.code || "",
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
        this.building = {};
        this.room = {};
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
        this.room = {};
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {code: this.room.company_cd, name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd) {
        this.building = {code: this.room.building_cd, name: this.room.building_name};
      }
    },
    sensorMacMask(val) {
      this.sensor_mac = Common.getMacMask(val)
    },
    searchMacMask(val){
      this.search = Common.getMacMask(val)
    },
    itemRowBackground(item) {
      return item.id == save_sensor_id  ? 'selected' : '' ;
    },
    // smartMacMask(val) {
    //   this.smart_mac = Common.getMacMask(val)
    // },



  }  // -----------methods 

}
// 01-06
</script>
<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
.dialog_content{
  padding:10px;
  border-radius: 30px;
}
h2{
  letter-spacing: -1px;
}
.sensor_content{
  padding:5px;
}
.normal_btn_color{
  background-color: #3a4f3f !important;
  color:white;
}
.new_btn_color{
  background-color: rgb(245, 245, 245) !important;
  color:rgb(68, 68, 68);
}
.cancel_btn_color{
  background-color: #88191a !important;
  color:white;
}
.close_btn_color{
  background-color: #909090 !important;
  color:rgb(255, 255, 255);
}
.normal_btn{
  width:100px;
  height:35px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.action_btn{
  width:70px;
  height:35px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.refresh_btn{
  width:100px;
  height:35px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.mini-gauge {
    margin-right:10px;
    max-width: 130px;
    .inner-text {
      width: 100%;
      height: 100%;
    }
  }
.inner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3a4f3f;
  font-weight: bold;
}
.sensor_title{
  font-size:1.5rem;
  letter-spacing: -1px;
  text-align:center;
}
.sensor_value{
  margin-top:5px;
  font-size:1.3rem;
}

</style>
