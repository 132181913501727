<template>
  <v-container >
    <v-layout wrap justify-center>
      <v-card class="ma-0 mt-0">
        <v-form>
          <div class="ma-2">탈취장치 정보 {{deodorizer_id}}
            <v-btn class="ml-1 mb-1 rounded-pill set-btn" style="height: 30px; width: 100px" v-if="deodorizer_id"
              @click="gotoSetting(deodorizer_id)">
              <v-icon class="mr-1 small" color="#3a4f3f">mdi-image-filter-vintage </v-icon>
              <span style="font-size:12px;">제어설정</span>
            </v-btn>
          </div>
            
          <v-select dense outlined class="ma-1" style="height: 45px; width: 365px;" v-show="false"
            id="company" 
            ref="company" 
            label="경영체✽" 
            v-model="company"
            :items="companyItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다.."
            item-text="company_name"
            return-object
            @change="changeCompany()"
            @focus="comboCompanies()"
            clearable
          />

          <v-select dense outlined  class="ma-1" style="height: 45px; width: 365px;" 
            id="farm" 
            ref="farm" 
            label="농장/사업장✽" 
            v-model="farm"
            :items="farmItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="farm_name"
            return-object
            @change="changeFarm()"
            @focus="comboFarms()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px; width: 365px;"
            id="building" 
            ref="building" 
            label="축사/건물/구역✽" 
            v-model="building"
            :items="buildingItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="building_name"
            return-object
            @change="changeBuilding()"
            @focus="comboBuildings()"
            clearable
          />
          <v-select dense outlined  class="ma-1" style="height: 45px;  width: 365px;"
            id="room" 
            ref="room" 
            label="돈방/위치✽" 
            v-model="room"
            :items="roomItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="room_name"
            return-object
            @change="changeRoom()"
            @focus="comboRooms()"
            clearable
          />

          <v-select dense outlined  class="ma-1" style="height: 45px; width: 365px;"
            id="device_type" 
            ref="device_type" 
            label="장치 종류✽" 
            v-model="device_type"
            :items="device_typeItems"
            :menu-props="{ top: false, offsetY: true }"
            item-text="name"
            return-object
            @change="changeDevicetype()"
            clearable
          />

          <v-text-field dense outlined class="ma-1" style="height: 45px; width: 365px;"
            id="device_name" 
            ref="device_name" 
            label="장치명✽" 
            v-model="device_name"
            maxlength="15"
            clearable
          />

          <v-layout wrap>
            <v-select dense outlined class="ma-1 shrink" style="height: 45px;  width: 255px;"
              id="plc" 
              ref="plc" 
              label="연결PLC✽" 
              v-model="plc"
              :items="plcItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @focus="comboPLC()"
            />

            <v-text-field dense outlined class="ma-1 shrink " style="height: 45px; width:100px;"
              id="btn_no" 
              ref="btn_no" 
              label="맵핑No✽" 
              placeholder="PLC NUMBER"
              v-model="btn_no"
              maxlength="5"
            />
          </v-layout>

          <v-combobox dense outlined class="ma-1 shrink" style="height: 45px;  width: 365px;"
            id="model" 
            ref="model" 
            label="모델명"
            :items="modelItems" 
            placeholder="모델(입력가능)" 
            v-model="model"
            @focus="listModels(true)"
          />

          <v-select dense outlined class="ma-1 shrink" style="height: 45px; width: 365px;"
            id="pump" 
            ref="pump" 
            label="연결펌프✽" 
            v-model="pump"
            :items="pumpItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @focus="comboPump()"
          />

          <v-select dense outlined class="ma-1 shrink" style="height: 45px; width: 365px;"
            id="compressor" 
            ref="compressor" 
            label="연결컴프레서✽" 
            v-model="compressor"
            :items="compressorItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @focus="comboCompressor()"
          />

          <v-layout>
            <v-text-field dense outlined class="ma-1 mr-1 ml-1" style="height: 45px; width: 165px;"
            id="consumeWater" 
            ref="consumeWater" 
            label="물 소비량✽" 
            v-model.number="consumeWater"
            type="number"
            suffix="리터/분"
            clearable
            />
            <v-text-field dense outlined class="ma-1 mr-1" style="height: 45px; width: 165px;"
            id="consumeAir" 
            ref="consumeAir" 
            label="에어 용량✽" 
            v-model.number="consumeAir"
            type="number"
            suffix="리터/20초"
            clearable
            />
          </v-layout>

          <v-layout>
          <v-menu
            v-model="menuSensorSetdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink ma-1" style="width: 178px" 
                id="setdate" 
                ref="setdate" 
                v-model="setdate"
                label="설치일✽"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="setdate"
              @input="menuSensorSetdate = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menuSensorDeldate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="ma-1 shrink" style="width: 178px"
                id="deldate" 
                ref="deldate" 
                v-model="deldate"
                label="중지일"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="deldate"
              @input="menuSensorDeldate = false"
            ></v-date-picker>
          </v-menu>
          </v-layout>

          <v-textarea outlined dense rows="2" name="input-7-4" style="height: auto; margin: -15px 3px 0px 5px"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
            persistent-hint :hint="hint"
          ></v-textarea>
          <v-card-actions class="d-block" >
            <v-row justify= "center" >
              <v-spacer ></v-spacer>
              <v-btn rounded class="normal-btn mr-2" style="height: 30px; width: auto;  margin-bottom: 15px"
                :loading="loadingSave" 
                id="save" 
                ref="save"
                type="submit" 
                @click.prevent="save()">
                {{ savemode }}
              </v-btn>
              <v-btn rounded class="new-btn mr-2" style="height: 30px; width: auto;  margin-bottom: 15px" 
                id="clear" 
                ref="clear" 
                @click="newRegister()">
                새로등록
              </v-btn>
              <v-btn rounded class="dialog-btn" style="height: 30px; width: auto;  margin-bottom: 15px" v-show="deodorizer_id" 
                id="delete" 
                ref="delete" 
                @click="deleteDeodorizer()">
                삭제
              </v-btn>
              <v-spacer ></v-spacer>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-card>
      
      <v-card class="ma-0 ml-1 mt-0" width="700px">
        <v-data-table dense nowrap height="650px" class="elevation-3  v-data-table" style=" margin-left: 1px margin-right: 0px "
          id="items" 
          ref="items"
          :loading="loadingList" 
          :headers="headers"
          :items="items"
          :items-per-page= "500"
          no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
          fixed-header
          hide-default-footer
          @click:row="infoDetail"
          sort-by="room"
          group-by="plc_name"
          show-group-by
          item-key="id"
          single-select
          :item-class="itemRowBackground"
          :search="search"
        >
          <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="9">
              <v-icon @click="toggle"
                >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
              </v-icon>
              PLC : {{ items[0].plc_name }}
            </th>
          </template>
        </v-data-table>

        <v-card-title>
          <v-text-field dense style="width:250px" class="shrink"
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="건물명 및 구역명으로 찾기"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>


        <v-card-actions class="justify-center">
          <v-btn rounded-pill class="elevation-5 ma-3 rounded-pill normal-btn" style="height: 35px;"
            id="refreshList" 
            ref="refreshList" 
            @click="refreshList()">
            <v-icon left dark>mdi-refresh</v-icon>
            목록고침
          </v-btn>
        </v-card-actions>
      </v-card>
        


    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import Common from "@/utils/custom/common.js";
var save_deodorizer_id = "";

export default {
  name: 'RegisterDeodorizer',


  data: () => ({
    search:'',

    items: [],
    headers: [
      // { text: '농장/사업장', align: 'left', value: 'farm', sortable: false, groupable: false, width: "155px", class: 'green darken-4 white--text',divider: false  },
      { text: '건물', align: 'left', value: 'building', sortable: false, groupable: false, width: "110px", class: 'green darken-4 white--text',divider: false  },
      { text: '구역', align: 'left', value: 'room', sortable: false, groupable: false, width: "130px", class: 'green darken-4 white--text',divider: false, fixed: true },
      // { text: '장치종류', align: 'left', value: 'type', sortable: false, groupable: false, width: "130px",class: 'green darken-4 white--text',divider: false  },
      { text: '장비식별명', align: 'left', value: 'device_name', sortable: false, groupable: false, width: "220px",class: 'green darken-4 white--text',divider: false  },
      { text: '제어PLC', align: 'left', value: 'plc_name', sortable: false, groupable: false, width: "130px",class: 'green darken-4 white--text',divider: false  },
      { text: '맵핑번호', align: 'center', value: 'btn_no', sortable: false, groupable: false, width: "80px",class: 'green darken-4 white--text',divider: false  },
      // { text: '장치모델명', align: 'center', value: 'model', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      // { text: '설치일', align: 'left', value: 'setdate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text',divider: false  },
      { text: '중지일', align: 'left', value: 'deldate', sortable: false, groupable: false, width: "110px",class: 'green darken-4 white--text' ,divider: false },
      { text: '비고', align: 'left', value: 'history', sortable: false, groupable: false, width: "350px",class: 'green darken-4 white--text' ,divider: false },
    ],


    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],

    device_type: "",
    device_typeItems : Common.comboBaseCode("OT"),

    device_name:"",

    plc: {},
    plcItems: [],

    btn_no: "",
    
    model:"",
    modelItems:[],

    tank: {},
    tankItems:[],
    compressor: {},
    compressorItems:[],
    pump: {},
    pumpItems:[],
    consumeWater: 0,
    consumeAir: 0,
    
    setdate: "",
    deldate: "",
    history: "",
    hint: "",

    // loadingMac: false,
    loadingSave: false,
    loadingList: false,

    deodorizer_id: "",

    menuSensorSetdate: false,
    menuSensorDeldate: false,

    setData:{},
     
    msgflag: true,

  }),
  created() {
    this.comboCompanies();
    this.comboFarms();
    this.comboBuildings();
    this.comboRooms();
    this.comboPLC();
    this.listModels(false);
    this.$store.commit("resMessage","");
    this.refreshList();
    sessionStorage.setItem("deviceTab",4)
  },
  computed: {
     savemode() {
        return this.deodorizer_id ? "수정저장" : "신규저장"; 
     }
  },

  methods: {
    newRegister() {   // 새로운 센서 등록 준비 입력항목 clear;
      this.clearDeviceData();
      this.$store.commit("resMessage","새로운 디바이스를 입력할 준비가 되었습니다.");
      this.$refs.company.focus();
    },

    clearDeviceData() {
      this.deodorizer_id = "";
      this.company = {};
      this.farm = {};
      this.building = {};
      this.room = {};

      this.device_type = {};

      this.device_name = "";

      this.plc = {};
      this.btn_no = "";
      this.model = "";

      this.tank = {};
      this.pump = {};
      this.compressor = {};

      this.consumeWater= 0;
      this.consumeAir = 0;

      this.setdate = "";
      this.deldate = "";
      this.history = "";
      this.hint = "";

    }, 
    checkData() {
      if (!this.farm.farm_cd) {
        this.$refs.farm.focus();
        return "농장/사업장을 선택하세요";
      }
      if (!this.building.building_cd) {
        this.$refs.building.focus();
        return "축사/건물/구역을 선택하세요";
      }
      if (!this.room.room_cd) {
        this.$refs.room.focus();
        return "돈방/위치를 선택하세요";
      }
      if (!this.device_type.code) {
        this.$refs.device_type.focus();
        return "장치 종류를 선택하세요";
      }
      if (!this.device_name.trim()) {
        this.$refs.device_name.focus();
        return "장치 식별명을 선택하세요";
      }
      if (!this.plc.mac) {
        this.$refs.plc.focus();
        return "장치명을 선택하세요";
      }
      if (!Common.isNumeric(this.btn_no)) {
        this.$refs.btn_no.focus();
        return "PLC 버튼번호를 입력하세요.";
      }
      if (!this.pump.id) {
        this.$refs.pump.focus();
        return "연결된 펌프를 선택하세요";
      }
      if (!this.compressor.id) {
        this.$refs.compressor.focus();
        return "연결된 컴프레서를 선택하세요";
      }
      if (this.consumeWater == null) {
        this.$refs.consumeWater.focus();
        return "물 소비량을 입력하세요.";
      }
      if (this.consumeWater < 0) {
        this.$refs.consumeAir.focus();
        return "에어 용량을 입력하세요.";
      }
      if (this.consumeAir < 0) {
        this.$refs.consumeAir.focus();
        return "에어 용량을 입력하세요.";
      }
      if (this.consumeAir == null) {
        this.$refs.consumeAir.focus();
        return "에어 용량을 입력하세요.";
      }
      // if (!this.model) {
      //   this.$refs.model.focus();
      //   return "탈취장비 모델번호를 선택입력하세요.";
      // }  
      if (!this.setdate) {
        this.$refs.setdate.focus();
        return "설치일을 선택입력하세요";
      }
      return "";
    },
    save() {
      // console.log("model", this.model);
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      this.loadingSave = true;
      save_deodorizer_id = this.deodorizer_id;
      if (this.deodorizer_id) {
        // console.log("업데이트");
        Apis.updateDeodorizer({
          id:  Number(this.deodorizer_id),
          mac: this.plc.mac,
          slave_id:  Number(this.plc.slave_id),
          type: this.device_type.code,
          btn_no: Number(this.btn_no),
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.device_name,
          pump: this.pump.id,
          compressor: this.compressor.id,
          consumewater: this.consumeWater,
          consumeair: Number(this.consumeAir) * 3,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          model: this.model.trim().toUpperCase(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.loadingSave = false;
              this.msgFlag = false;
              this.save_deodorizer_id = this.deodorizer_id;
              this.clearDeviceData();
              this.refreshList();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      } else {
        // console.log("인서트",this.company_cd);

        Apis.insertDeodorizer({
          mac: this.plc.mac,
          slave_id: Number(this.plc.slave_id),
          btn_no: Number(this.btn_no),
          type: this.device_type.code,
          company_cd: this.company.company_cd,
          farm_cd : this.farm.farm_cd,
          building_cd :	this.building.building_cd,
          room_cd :	this.room.room_cd,
          device_name :	this.device_name,
          pump: this.pump.id,
          compressor: this.compressor.id,
          consumewater: this.consumeWater,
          consumeair: Number(this.consumeAir) * 3,
          setdate :	this.setdate,
          deldate :	this.deldate,
          history : this.history.trim(),
          model: this.model.trim().toUpperCase(),
          },(res) => {  
            if (res.result) {
              this.$store.commit("resMessage",res.message);
              this.msgFlag = false;
              this.loadingSave = false;
              this.msgFlag = false;
              // console.log("저장");
              this.refreshList();
              save_deodorizer_id = this.deodorizer_id;
              this.clearDeviceData();
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
              this.loadingSave = false;
              console.log("API 호출 오류",err)
              alert(err);
          }
        )
      }
    },
    deleteDeodorizer() {
      this.$store.commit("resMessage","");
      if ( this.deodorizer_id ) {
        if(confirm(`주의 ! \n ${this.device_name} 의 \n 탈취장치 정보가 완전 삭제됩니다. \n 삭제하시겠습니까?`) == true){
          this.loadingMac = true;
          // console.log("mac", this.smart_mac.replace(/:/gi, ''));
          Apis.deleteDeodorizer({
            id: this.deodorizer_id,
            },(res) => {  
              if (res.result) {
                this.loadingMac = false;
                this.msgFlag = false;
                this.clearDeviceData();
                this.$store.commit("resMessage",res.message);
                save_deodorizer_id = "";
                this.refreshList();
              } else {
                alert(res.message);
                this.loadingMac = false;
              }
            }).catch( (err) => {  // API 오류 처리
                console.log("API 호출 오류",err)
                this.loadingMac = false;
                // alert(err);
            }
          ); 
        }
      }
    },
    async infoDetail(value,row) {
      row.select(true)
      this.$store.commit("resMessage","");
      this.clearDeviceData();
      await this.listModels(false);

      Apis.infoDeodorizer({
        id: value.id,
        },(res) => {  
          if (res.result) {
            // console.log(res.data)
            this.deodorizer_id = res.data.id;
            this.model = res.data.model;
            this.company = {company_cd: res.data.company.code, company_name: res.data.company.name};
            this.farm = {farm_cd: res.data.farm.code, farm_name: res.data.farm.name};
            this.building = {building_cd: res.data.building.code, building_name: res.data.building.name};
            this.room = {room_cd: res.data.room.code, room_name: res.data.room.name };
            this.device_type = res.data.type;
            this.device_name = res.data.device_name;
            this.plc = res.data.plc;
            this.btn_no = res.data.btn_no;

            this.pumpItems.push(res.data.pump);
            this.pump = res.data.pump;
            this.compressorItems.push(res.data.compressor);
            this.compressor = res.data.compressor;
            this.consumeWater = res.data.consumeWater;
            this.consumeAir = (res.data.consumeAir)/3;

            this.setdate = res.data.setdate;
            this.deldate = res.data.deldate;
            this.history = res.data.history; 
            this.hint = res.data.chguser + "님이 " + res.data.chgdate + "에 최종 수정";
            // this.loadingMac = false;
            this.$store.commit("resMessage",res.message);
          } else {
            alert(res.message);
            // this.loadingMac = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            // this.loadingMac = false;
            // alert(err);
        }
      ); 
    },
    refreshList() {
      this.clearDeviceData();

      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loadingList = true;
      // console.log("리스트");

      Apis.listDeodorizers({
      } ,(res) => {  // 정상처리
        this.items = res.data;
        // console.log(res.data);
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loadingList = false;
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listDeodorizers 호출 오류",err);
        this.$router.push("/");
        this.loadingRoom = false;
        this.msgFlag = true;
      }) 
    }, 

    async listModels(flag) {
      if (flag && !this.plc) {
        this.$refs.plc.focus();
        alert("제어할 PLC를 먼저 선택하세요");
        this.$store.commit("resMessage","제어할 PLC를 먼저 선택하세요");
        return;
      }
      await Apis.listDeodorizerModels({
        // stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.modelItems = res.data;
            // console.log(res.data);
          } else {
            this.modelItems = [];
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("listDeodorizerModels API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    comboCompanies() {
      Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = res.data;
          } else {
            this.companyItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
    },
    comboFarms() {
      Apis.comboFarms({
        // company_cd: this.company && this.company.company_cd || "",
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.farmItems = res.data;
          } else {
            this.farmItems = [];
            alert(res.message);  
          } 
          }).catch( (err) => {  // API 오류 처리
          console.log("comboFarms API 호출 오류",err)
          alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      // if (this.farm && this.farm.company_cd) {
        // this.company = {company_cd: this.farm.company_cd, company_name: this.farm.company_name};
        this.building = {};
        this.room = {};
      // }
    },
    comboBuildings() {
      Apis.comboBuildings({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
        if (res.result) {
            this.buildingItems = res.data;
          } else {
            this.buildingItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.building && this.building.company_cd) {
        this.company = {company_cd: this.building.company_cd, company_name: this.building.company_name};
      }
      if (this.building && this.building.farm_cd) {
        this.farm = {farm_cd: this.building.farm_cd, farm_name: this.building.farm_name};
      }
        this.room = {};
    },
    comboRooms() {
      Apis.comboRooms({
        company_cd:this.company &&  this.company.company_cd || "",
        farm_cd: this.farm && this.farm.farm_cd || "",
        building_cd: this.building && this.building.building_cd || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        name_flag: true, 
        },(res) => {  
          if (res.result) {
            this.roomItems = res.data;

          } else {
            this.roomItems = [];
            alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {company_cd: this.room.company_cd, company_name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {farm_cd: this.room.farm_cd, farm_name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd) {
        this.building = {building_cd: this.room.building_cd, building_name: this.room.building_name};
      } 
    },
    changeDevicetype(){
      if (this.room && this.room.company_cd) {
        this.company = {company_cd: this.room.company_cd, company_name: this.room.company_name};
      }
      if (this.room && this.room.farm_cd) {
        this.farm = {farm_cd: this.room.farm_cd, farm_name: this.room.farm_name};
      }
      if (this.room && this.room.building_cd && this.device_type) {
        this.building = {building_cd: this.room.building_cd, building_name: this.room.building_name};
        this.device_name = this.room.room_name + " " + this.device_type.name;
      } else{
        this.device_name = "";
      }
    },

    comboPLC() {
      Apis.comboPLC({
        farm_cd: this.farm && this.farm.farm_cd || "",
        },(res) => {  
          if (res.result) {
            this.plcItems = res.data;
            // console.log(res.data);
          } else {
            this.plcItems = [];
            // alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboPLC API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    comboPump() {
      if (!this.plc.mac) {
        this.$store.commit("resMessage","PLC를 먼저 선택하세요");
        this.$refs.plc.focus();
        // alert("PLC를 먼저 선택하세요");
        return;
      }
      Apis.comboDeodorizerControlDevices({
        mac: this.plc.mac,
        slave_id: this.plc.slave_id,
        division: "OD04",
        },(res) => {  
          if (res.result) {
            this.pumpItems = res.data;
            // console.log(res.data);
          } else {
            this.pumpItems = [];
            // alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboDeodorizerControlDevices API 호출 오류",err)
            alert(err);
      1 }
      ) 
    },
    comboCompressor() {
      if (!this.plc.mac) {
        this.$store.commit("resMessage","PLC를 먼저 선택하세요");
        this.$refs.plc.focus();
        // alert("PLC를 먼저 선택하세요");
        return;
      }
      Apis.comboDeodorizerControlDevices({
        mac: this.plc.mac,
        slave_id: this.plc.slave_id,
        division: "OD01",
        },(res) => {  
          if (res.result) {
            this.compressorItems = res.data;
            // console.log(res.data);
          } else {
            this.compressorItems = [];
            // alert(res.message);  
          } 
        }).catch( (err) => {  // API 오류 처리
            console.log("comboDeodorizerControlDevices API 호출 오류",err)
            alert(err);
        }
      ) 
    },

    itemRowBackground(item) {
      // console.log("key",item.id);
      return item.id == save_deodorizer_id ? 'selected' : '' ;
    },
    gotoSetting(id) {
      this.$router.push({
        name: "DeodorizerSet",
        params: {
          id: id
        }
      });

    }


  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
*{
  letter-spacing: -0.5px;
}
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}
::v-deep .v-text-field__suffix {
  font-size: 13px;
  letter-spacing: -1px;
}

.set-btn{
  background-color: #e8e7e7 !important;
  color:#3a4f3f;
  font-size:11px;
}
.normal-btn{
  background-color: #3a4f3f !important;
  color:white;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
}
.new-btn{
  color:#3a4f3f !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  width:100px;
  height:45px !important;
  font-weight:bold;
  font-size:15px;
  letter-spacing: -0.5px;
  border-color:transparent;
  background-color: #88191a !important;
}

.refer_btn{
  height:45px; 
  width:190px; 
  background-color: #3a4f3f; 
  color:white; 
  border-color: transparent;
}
</style>
